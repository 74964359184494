@import "./scss/index.scss";
* {
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  user-select: none;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  min-height: calc(100vh - 15vh - 75px);
  height: 100%;
}
@media screen and (max-width: $media-breackpoint-up-xl) {
  main {
    height: 100%;
    min-height: calc(100vh - 15vh - 150px);
  }
}
a {
  text-decoration: none;
}

@keyframes loader {
  0% {
    background-position: -190px 0;
  }
  100% {
    background-position: 190px 0;
  }
}

.loader-effect {
  background-color: $background-gradient;
  border-radius: 10px;
  background-image: linear-gradient(
    90deg,
    $background-gradient 0%,
    rgba(0, 0, 0, 0.05) 20%,
    $background-gradient2 40%,
    $background-gradient 100%
  );
  background-size: 190px;
  animation: loader 1.5s infinite linear;
}
