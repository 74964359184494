@import "../../scss/index.scss";

.movie {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  padding: 10px;

  &-title {
    width: 100%;
    color: black;
    margin: auto 0;
    flex: 1;
    text-align: center;
    padding: 10px;
    font-weight: 600;
  }
  &-image {
    height: 230px;
    width: 90%;
    border-radius: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .movie {
    &-title {
      padding: 5px;
      margin: auto;
      height: 20px;
      width: 70%;
    }
    &-image {
      max-width: 100%;
      height: 180px;
      margin: 15px;
    }
  }
}
