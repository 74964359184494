@import "../../scss/index.scss";
.list {
  padding-bottom: 1em;
  h2 {
    margin-left: 30px;
  }
  &_title {
    width: 70%;
    height: 5vh;
    max-height: 25px;
    max-width: 350px;
    margin-left: 25px;
  }
  &_scroll {
    white-space: nowrap;
    display: flex;
    overflow-x: auto;
    margin: 0 10px;
    &::-webkit-scrollbar {
      width: 16px;
      height: 15px;
      border: 5px solid transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $background-grey;
      border-radius: 15px;
    }
    &-item {
      text-align: center;
      width: auto;
      margin: 0 10px;
      &-container {
        height: 100%;
        min-width: 190px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;

        img {
          max-width: 90%;
          height: 100%;
          max-height: 200px;
          border-radius: 10px;
        }
        p {
          width: 100%;
          color: black;
          margin: auto 0;
          white-space: pre-wrap;
          flex: 1;
          text-align: end;
        }
        &-title {
          text-align: center !important;
          padding-top: 10px;
          font-weight: 600;
        }
        span {
          position: absolute;
          display: block;
          top: 165px;
          right: 10px;
          background-color: $primary-color;
          padding: 10px;
          color: white;
          border-color: black;
          border-radius: 20px;
          border: 2px solid rgb(43, 209, 40);
        }
      }
    }
  }
}
