.card {
  &_backImage {
    position: absolute;
    top: 0;
    z-index: -10;
    height: 100%;
    right: 0;
    background-size: cover;
    border-radius: 11px;
    width: 80%;
    background-position: -100% 10% !important;
    img {
      height: 100%;
      width: 100%;
    }
  }
  &_text {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-blend-mode: multiply;
    background: linear-gradient(
      to right,
      rgb(231, 229, 229) 50%,
      transparent 100%
    );
    z-index: 2;
    border-radius: 10px;
    &-movie {
      position: relative;
      padding-left: 25px;
      width: 65%;
      display: flex;
      align-items: center;
      flex-direction: row;
      &-image {
        position: relative;
        margin-right: 20px;
        height: 120px;
      }
    }
    &-overview {
      padding: 8px 25px;
      width: 75%;
      &-text {
        font-size: 17px;
        color: #383838;
        margin-block: 0 !important;
        text-align: left;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .card {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    border-radius: 10px;
    transition: all 0.4s;
    &_backImage {
      position: absolute;
      top: 0;
      right: 0;
      background-size: cover;
      border-radius: 11px;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }
    }
    &_text {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      z-index: 2;
      padding: 0;
      background: rgba(0, 0, 0, 0.5);
      color: white;
      &-movie {
        position: relative;
        padding: 0 20px;
        &-image {
          display: none;
        }
      }
      &-overview {
        padding-left: 25px;
        width: 85%;
        &-text {
          color: #ebe9e9;
          margin-block: 0 !important;
          width: 100%;
          font-size: 15px;
          height: auto;
          word-wrap: break-word;
        }
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .card {
    display: flex;
    flex-direction: column;
    &_backImage {
      position: relative;
      background-size: cover;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    &_text {
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5px;
      &-movie {
        position: relative;
        padding: 0;
        width: 100%;
        &-image {
          display: none;
        }
        h5,
        h3 {
          display: inline-block;
          margin-block: 0 !important;
          color: rgb(30, 30, 30) !important;
        }
        h3 {
          font-size: 17px;
        }
        h5 {
          font-size: 14px;
        }
        h5::before {
          content: "(";
        }
        h5::after {
          content: ")";
        }
      }
      &-overview {
        width: 100%;
        padding: 0;
        margin-top: 5px;
        &-text {
          color: #383838;
          font-size: 12px;
          height: auto;
          word-wrap: break-word;
        }
      }
    }
  }
}
