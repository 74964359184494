@import "../../scss/index.scss";

nav {
  display: flex;
  position: relative;
  background-color: $primary-color;
  width: 100%;
  justify-content: space-between;
  text-decoration: none;
  text-align: center;
  padding: 15px 0;
  transition: 0.5s;
  .logo {
    margin-left: 20px;
    cursor: pointer;
    &_text {
      display: none;
      font-size: 30px;
      font-weight: 800;
      color: $font-light;
    }
    &_img {
      height: 40px;
      display: block;
    }
    strong {
      color: $font-red;
    }
  }
  ol {
    display: flex;
    list-style: none;
    margin: auto 20px;
    li {
      margin: 0 5px;
      &:hover {
        a {
          color: $primary-color;
          background-color: $background-light;
          transition: 0.5s;
          border-radius: 15px;
          text-decoration: none;
        }
      }
      a {
        color: $font-light;
        font-size: 20px;
        text-decoration: none;
        text-transform: capitalize;
        letter-spacing: 1px;
        list-style: 80px;
        padding: 5px 10px;
        transition: 0.5s;
        &.active {
          color: $primary-color;
          background-color: $background-light;
          transition: 0.5s;
          border-radius: 15px;
          text-decoration: none;
        }
      }
    }
  }
  .search_box {
    display: flex;
    margin: auto 0;
    height: 35px;
    line-height: 35px;
    input {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border: none;
      outline: none;
      background-color: $background-light;
      height: 100%;
      padding: 0 10px;
      font-size: 20px;
      width: 350px;
    }
    span {
      color: $primary-color;
      background-color: $background-light;
      font-size: 20px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 100%;
      position: relative;
      padding: 0 10px;
      cursor: pointer;
      z-index: 1;
      &:hover {
        color: white;
      }
      &::after {
        height: 100%;
        width: 0%;
        content: "";
        background-color: $font-red;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: 0.5s;
      }
      &:hover::after {
        width: 100%;
      }
    }
  }
  .bar {
    position: relative;
    margin: auto;
    display: none;
    span {
      position: absolute;
      color: #fff;
      font-size: 35px;
    }
  }
}
input[type="checkbox"] {
  -webkit-appearance: none;
  display: none;
}

@media screen and (max-width: $media-breackpoint-up-xl) {
  nav {
    display: block;
    .logo {
      display: inline-block;
      padding: 15px 30px;
      text-decoration: none;
      &_text {
        display: block;
      }
      &_img {
        display: none;
        height: 50px;
      }
    }
    .search_box {
      width: 90%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 15px;
      input {
        width: 90%;
      }
    }
    ol {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      height: 0;
      visibility: hidden;
      transition: 0.5s;
      li {
        text-align: center;
        transition: 0.5s 0.1s all;
        opacity: 0;
        a {
          color: $font-dark;
          font-size: 28px;
          padding: 15px;
          display: block;
          margin-top: 5px;
          &:hover,
          &.active {
            color: #fff;
            background-color: #324b66;
            transition: 0.5s;
            border-radius: 15px;
            text-decoration: none;
          }
        }
        &:nth-child(1) {
          transform: translateX(-150px);
        }
        &:nth-child(2) {
          transform: translateX(-200px);
        }
      }
    }
    .bar {
      display: block;
      position: absolute;
      top: 30px;
      right: 80px;
      cursor: pointer;
      #times {
        display: none;
      }
    }
  }
  #check:checked ~ nav .bar #times {
    display: block;
  }
  #check:checked ~ nav .bar #bars {
    display: none;
  }
  #check:checked ~ nav ol {
    visibility: visible;
    height: 150px;
    padding: 10px 0;
    border-radius: 10px;
  }
  #check:checked ~ nav ol li:nth-child(1),
  #check:checked ~ nav ol li:nth-child(2) {
    transform: translateX(0);
    opacity: 1;
  }
}
@media screen and (max-width: $media-breackpoint-up-sm) {
  nav {
    display: block;
    .logo {
      display: block;
      padding: 10px 10px;
      &_text {
        display: none;
      }
      &_img {
        display: block;
        height: 35px;
      }
    }
    .search_box {
      width: 90%;
      display: inline-flex;
      justify-content: center;
      margin-bottom: 15px;
      input {
        width: 85%;
      }
    }
    ol {
      display: flex;
      flex-direction: column;
      visibility: hidden;
      transition: 0.5s;
      li {
        text-align: center;
        transition: 0.5s 0.1s all;
        opacity: 0;
        &:nth-child(1) {
          transform: translateX(-150px);
        }
        &:nth-child(2) {
          transform: translateX(-200px);
        }
        a {
          font-size: 22px;
          padding: 15px;
          display: block;
        }
      }
    }
    .bar {
      display: block;
      position: absolute;
      top: 20px;
      right: 80px;
      cursor: pointer;
      #times {
        display: none;
      }
    }
  }
  #check:checked ~ nav .bar #times {
    display: block;
  }
  #check:checked ~ nav .bar #bars {
    display: none;
  }
  #check:checked ~ nav ol {
    visibility: visible;
    height: 130px;
  }
  #check:checked ~ nav ol li:nth-child(1),
  #check:checked ~ nav ol li:nth-child(2) {
    transform: translateX(0);
    opacity: 1;
  }
}
