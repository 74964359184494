.footer {
  height: auto;
  min-height: 15vh;
  background-color: #1b2c3e;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 0 10px 0;
  &_links {
    padding: 5px 0 15px 0;

    white-space: normal;
    text-align: center;
    align-items: center;
    a {
      display: block;
      color: white;
      padding: 2px 25px;
    }
  }
  &_img {
    height: 7vw;
    max-height: 40px;
  }
}
@media only screen and (max-width: 750px) {
  .footer {
    flex-direction: column;
    &_links {
      display: flex;
      flex-direction: row;
    }
  }
}
