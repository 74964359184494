@import "../../scss/index.scss";
body {
  overflow-x: hidden;
}

.transform1 {
  display: flex;
  width: 100%;
  height: 50vh;
  will-change: transform;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  .transform2 {
    position: relative;
    display: flex;
    overflow: hidden;
    transition: all 0.4s;
    width: 100%;
    max-width: 550px;
    height: 100%;
    max-height: 350px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 5px 5px 5px rgba(50, 50, 73, 0.4),
      0 10px 10px -10px rgba(50, 50, 73, 0.3);
    background-color: white;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 550px;
    margin-top: 20px;
    &-btn {
      margin: 25px 0;
      margin: 0 10px;
      border: 2px solid $primary-color;
      background-color: $background-light;
      color: $primary-color;
      font-weight: 700;
      padding: 10px 25px;
      border-radius: 25px;
      text-transform: uppercase;
      transition: all 0.3s ease;
      font-size: 25px;
      letter-spacing: 2px;
      &:hover {
        background-color: $primary-color;
        color: $font-light;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .transform2 {
    position: relative;
    display: flex;
    overflow: hidden;
    transition: all 0.4s;
    width: 80%;
    max-width: 550px;
    height: 250px;
    will-change: transform;
    border-radius: 10px;
    background-color: white;
  }
  .buttons-btn {
    font-size: 15px;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 420px) {
  .transform2 {
    height: 375px;
  }
}
