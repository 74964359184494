.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  font-family: "Lato";
  &-back {
    margin: 1em auto;
    span {
      font-size: 3em;
      color: #fff;
      background-color: #324b66;
      display: table-cell;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3), 0 5px 0 #ccc;
      padding: 0 15px;
      line-height: 100px;
      animation: animate 2s infinite;
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
      &:nth-child(4) {
        animation-delay: 0.3s;
      }
      &:nth-child(5) {
        animation-delay: 0.4s;
      }
      &:nth-child(6) {
        animation-delay: 0.5s;
      }
      &:nth-child(7) {
        animation-delay: 0.6s;
      }
    }
  }
}
@keyframes animate {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
    box-shadow: 0 15px 0 rgb(177, 43, 43);
  }
  100% {
    transform: translateY(0px);
  }
}
