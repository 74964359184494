@import "../../scss/index.scss";

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  &_header {
    z-index: 1;
    height: auto;
    padding: 0 15px 30px 15px;
    background-color: $primary-color;
    color: $font-light;
    h2 {
      margin-left: 20px;
    }
  }
  &_options {
    background-color: $background-light;
    border-top-left-radius: 3em;
    border-top-right-radius: 3em;
    margin-top: -40px;
    z-index: 5;
    padding: 20px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 25px;
  }
}

@media only screen and (max-width: 1025px) {
  .search_options {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: 800px) {
  .search_options {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .search_options {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
}
@media only screen and (max-width: 300px) {
  .search_options {
    grid-template-columns: repeat(1, 1fr);
  }
}
