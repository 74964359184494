.skeleton_movie {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 190px;
  height: 290px;
  margin: 5px;
  &-title {
    padding: 5px;
    margin: auto;
    height: 20px;
    width: 70%;
  }
  &-image {
    max-width: 100%;
    height: 220px;
    margin: 15px;
  }
}

@media only screen and (max-width: 425px) {
  .skeleton_movie {
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 140px;
    height: 200px;
    margin: 5px;
    &-title {
      padding: 5px;
      margin: auto;
      height: 20px;
      width: 70%;
    }
    &-image {
      max-width: 100%;
      height: 220px;
      margin: 15px;
    }
  }
}
