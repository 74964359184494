@import "../../scss/index.scss";

.recommendation {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  h1 {
    margin-block-end: 0.2em;
  }
}

.recommendation_list {
  header {
    height: 70px;
    background-color: $primary-color;
    padding: 10px 15px 35px 15px;
    position: relative;
    z-index: 0;
    color: $font-light;
  }
}
